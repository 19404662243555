.xp-footer {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    height: 36px;
    z-index: 9999;
  }
  
  .xp-footer-inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
    padding: 0 2px;
    background: linear-gradient(to bottom, #245edb 0%, #3f8cf3 9%, #245edb 18%, #245edb 92%, #333 100%);
  }
  
  .xp-footer-start {
    display: flex;
    align-items: center;
    height: 100%;
  }
  
  .start-button {
    position: relative;
    display: inline-block;
    font-size: 18px;
    color: white;
    font-style: italic;
    padding: 5px 25px 5px 15px;
    text-shadow: 1px 1px 1px #333;
    border-radius: 0px 10px 15px 0px;
    border: none;
    transform: skewX(-3deg);
    left: -5px;
    box-shadow: 0px 5px 10px #79ce71 inset;
    background: radial-gradient(circle, #5eac56 0%, #3c873c 100%) center / cover no-repeat;
    display: flex;
    align-items: center;
}
  
  .start-button img {
    margin-right: 5px;
    height: auto;
    width: 25px;
  }
  
  .xp-footer-end {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 5px;
    background: linear-gradient(to bottom, #00639b 0%, #21afff 9%, #009bf1 18%, #0096e9 92%, #333 100%);
    box-shadow: inset 1px 0 #1f78b1, inset 2px 0 #44a4e8;
  }
  
  .time-display {
    color: white;
    font-size: 11px;
    font-weight: bold;
    padding: 2px 5px;
  }