body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.info-button {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 16px;
  padding: 0;
}

.info-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.xp-footer-end {
  display: flex;
  align-items: center;
  height: 100%;
}

.lastfm-stalker-button {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;
  height: 16px;
  width: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  min-width: 0;
}

.lastfm-stalker-button img {
  height: 16px;
  width: 16px;
  display: block;
}

.lastfm-stalker-button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.time-display {
  font-size: 11px;
  font-family: 'Tahoma', sans-serif;
  line-height: 16px;
  padding-left: 2px;
  display: flex;
  align-items: center;
  height: 100%;
}

.xp-footer-end * {
  margin: 0;
  padding: 0;
}

ul {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-left: 30px;
}

span.playcount {
  background: #ece9d8;
  padding: 1px 3px;
}

.generation-container {
  padding: 10px;
  position: relative;
}

.fading.grid-image {
  opacity: 50%;
  filter: blur(3px);
  transition: opacity 0.5s, filter 0.5s;
}

.grid-image:not(.fading) {
  opacity: 100%;
  filter: blur(0px);
  transition: opacity 0.5s, filter 0.5s;
}

img.loading-gif.fading {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
}

.title-bar-controls button:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.fading.np-image {
  filter: blur(2px);
  opacity: 80%;
  transition: filter 0.5s, opacity 0.5s;
}

.np-image:not(.fading) {
  filter: blur(0px);
  opacity: 100%;
  transition: filter 0.5s, opacity 0.5s;
}

.tab-button:disabled {
  opacity: 50%;
  cursor: not-allowed;
}

.tab-button:disabled:hover {
  background: linear-gradient(180deg, #fff, #fafaf9 26%, #f0f0ea 95%, #ecebe5);
  margin-left: -1px;
  margin-right: 2px;
  border-radius: 0;
  border-color: #91a7b4;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  border-top: 1px solid #91a7b4;
  box-shadow: unset;
  padding: 0 12px 3px;
}

.scrollable-content {
  height: calc(100% - 50px); /* Adjust if needed to account for the title bar */
  overflow-y: hidden;
}

.scrollable-content .tabs {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.scrollable-content menu[role="tablist"] {
  flex-shrink: 0;
}

.scrollable-content article[role="tabpanel"] {
  flex-grow: 1;
  overflow-y: auto;
}

.scrollable-content article[role="tabpanel"]#tab-grid,
.scrollable-content article[role="tabpanel"]#tab-recentTracks {
  overflow-y: hidden;
}

.title-bar{
  cursor: move;
}